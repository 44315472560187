<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Jimbo
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            L'aventure démare ici 🚀
          </p>
          <p class="mb-2">
            Gérez vos relations client simplement
          </p>
        </v-card-text>
        <v-card-text v-if="companyName.length > 0">
          Vous allez rejoindre :
          <v-chip
            :color="companyColor"
          >
            {{ companyName }}
          </v-chip>
        </v-card-text>
        <v-card-text v-if="alert.enabled">
          <v-alert
            type="error"
          >
            {{ alert.text }}
          </v-alert>
        </v-card-text>
        <!-- login form -->
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-card-text>
            <!--             <v-text-field
              v-model="username"
              outlined
              label="Nom d'utilisateur"
              placeholder="David"
              hide-details
              class="mb-3"
            ></v-text-field> -->

            <v-text-field
              v-model="lastName"
              outlined
              label="Nom"
              placeholder="Lassoula"
              :rules="[v => !!v || 'Le nom est requis']"
              hide-details
              class="mb-3"
            ></v-text-field>
            <v-text-field
              v-model="firstName"
              outlined
              label="Prénom"
              placeholder="David"
              hide-details
              :rules="[v => !!v || 'Le prénom est requis']"
              class="mb-3"
            ></v-text-field>
            <v-text-field
              v-model="email"
              :error="mailError"
              :hide-details="!mailError"
              :error-messages="mailError ? 'Cet e-mail est déjà utilisé' : ''"
              outlined
              label="Email"
              placeholder="david@formatop.fr"
              :rules="[v => !!v || 'L\'adresse e-mail est requise']"
              class="mb-3"
              @change="mailError = false"
            ></v-text-field>

            <v-text-field
              v-model="password"
              class="mb-3"
              outlined
              hide-details
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Mot de passe"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :rules="passwordRules.concat(passwordConfirmationRule)"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
            <v-text-field
              v-model="passwordConfirm"
              outlined
              :type="isPasswordConfirmVisible ? 'text' : 'password'"
              label="Confirmer le mot de passe"
              :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              class="mb-3"
              :rules="passwordConfirmRules.concat(passwordConfirmationRule)"
              @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
            ></v-text-field>

            <v-checkbox
              v-model="cgu"
              class="mt-1"
              :rules="[v => !!v || 'Vous devez accepter les CGUS.']"
            >
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">J'accepte les</span><a href="javascript:void(0)">CGU.</a>
                </div>
              </template>
            </v-checkbox>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="register"
            >
              Créer un compte
            </v-btn>
          </v-card-text>

          <!-- create new account  -->
          <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2">
              Vous avez déjà un compte ?
            </span>
            <router-link :to="{ name:'login' }">
              Connectez-vous
            </router-link>
          </v-card-text>
        </v-form>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">ou</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social link -->
        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            v-model="cgu"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  metaInfo: {
    title: 'Création de compte',
    titleTemplate: '%s - Jimbo',
  },
  data() {
    return {
      alert: {
        enabled: false,
        text: '',
      },
      mailError: false,
      cgu: false,
      passwordRules: [v => !!v || 'Le mot de passe est requis'],
      passwordConfirmRules: [v => !!v || 'La confirmation de mot de passe est requise'],
      valid: true,
      firstName: '',
      lastName: '',
      passwordConfirm: '',
      password: '',
      email: '',
      companyName: '',
      companyColor: '',
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      isPasswordVisible: false,
      isPasswordConfirmVisible: false,
      socialLink: [
        {
          icon: mdiFacebook,
          color: '#4267b2',
          colorInDark: '#4267b2',
        },
        {
          icon: mdiTwitter,
          color: '#1da1f2',
          colorInDark: '#1da1f2',
        },
        {
          icon: mdiGithub,
          color: '#272727',
          colorInDark: '#fff',
        },
        {
          icon: mdiGoogle,
          color: '#db4437',
          colorInDark: '#db4437',
        },
      ],
    }
  },
  computed: {
    passwordConfirmationRule() {
      if (this.password === this.passwordConfirm) {
        return true
      }

      return 'Le mot de passe et la confirmation doivent correspondre'
    },
  },
  mounted() {
    if (this.$route.query.inviteToken) {
      this.$http.get(`${process.env.VUE_APP_API_URL}/companyInvite/get-infos?token=${this.$route.query.inviteToken}`)
        .then(res => {
          if (res.status === 200 && res.data.status === 'pending') {
            this.companyName = res.data.companyName
            this.email = res.data.email
            this.companyColor = 'primary'
          } else if (res.status === 200 && res.data.status === 'accepted') {
            this.alert.text = 'Cette invitation a déjà été utilisée'
            this.alert.enabled = true
          } else {
            this.alert.text = 'Erreur lors de la vérification de l\'invitation, veuillez vérfier le lien'
            this.alert.enabled = true
          }
        })
        .catch(err => {
          this.alert.text = 'Erreur lors de la vérification de l\'invitation, veuillez vérfier le lien'
          this.alert.enabled = true
          console.log(err)
        })
    }
  },
  methods: {
    register() {
      if (this.$refs.form.validate()) {
        const newUser = {
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.password,
          passwordConfirm: this.passwordConfirm,
          email: this.email,
          invite: this.$route.query.inviteToken ? this.$route.query.inviteToken : null,
        }
        this.$http.post(`${process.env.VUE_APP_API_URL}/users/register`, newUser)
          .then(res => {
            console.log(res)

            if (res.status === 200) {
              this.$store.commit('auth_success', res.data)
              localStorage.setItem('token', res.data.token)
              this.$http.defaults.headers.common.Authorization = `Bearer ${res.data.token}`
              if (!res.data.user.companies.length > 0) {
                this.$router.push({ name: 'onboarding' })
              } else {
                this.$router.push({ name: 'dashboard' })
              }
            }
            console.log(res)
          })
          .catch(err => {
            if (err.response.status === 409) {
              this.mailError = true
            }
            console.log(err.response)
          })
          .finally()
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
